const fetchDownloadData = (slug) => {
    window.location.assign(`/download/${slug}`)
}
const goBack = () => {
    if (window.history.replaceState) {
        window.history.replaceState(null, null, window.location.href)
    }
    window.history.back()
}

document.querySelectorAll('.back-btn').forEach((item) => {
    item.addEventListener('click', goBack)
})


document.querySelectorAll('.back-btn').forEach((item) => {
    item.addEventListener('click', goBack)
})

const redirectLink = (url) =>{
    window.location.href = url;
}

document.querySelectorAll('.testimonial').forEach((item) => {
    item.addEventListener("click",()=>{
        console.log(item.getAttribute("url"))
        window.open(item.getAttribute("url"), '_blank').focus()
    })
  
})