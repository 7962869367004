// import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import Swiper, { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])

var swiper = new Swiper('.mySwiper', {
    slidesPerView: 3,
    keyboard: {
        enabled: false,
    },
    pagination: {
        el: '.swiper-pagination-0',
        clickable: true,
        renderBullet: function (index, className) {
            return `<div class="border-b-2 w-${
                index == 0 ? 12 : 12 - 2 * index
            } mr-3 ${className}"></div>`
        },
    },
    navigation: {
        nextEl: '.swiper-button-next-u',
        prevEl: '.swiper-button-prev-u',
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
            spaceBetween: 0,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 0,
        },
    },
})

var swiper1 = new Swiper('.swiper1', {
    slidesPerView: 1.4,
    spaceBetween: 20,
    keyboard: {
        enabled: false,
    },

    navigation: {
        nextEl: '.swiper-button-next-1',
        prevEl: '.swiper-button-prev-1',
    },
    pagination: {
        el: '.swiper-pagination-1',
        clickable: true,
        renderBullet: function (index, className) {
            return `<div class="border-b-2 w-${
                index == 0 ? 12 : 12 - 2 * index
            } mr-3 ${className}"></div>`
        },
    },
    breakpoints: {
        768: {
            slidesPerView: 4,
            spaceBetween: 10,
        },
    },
})

var swiper2 = new Swiper('.swiper2', {
    slidesPerView: 1.4,
    spaceBetween: 20,
    keyboard: {
        enabled: false,
    },
    pagination: {
        el: '.swiper-pagination-2',
        clickable: true,
        renderBullet: function (index, className) {
            return `<div class="border-b-2 w-${
                index == 0 ? 12 : 12 - 2 * index
            } mr-3 ${className}"></div>`
        },
    },

    navigation: {
        nextEl: '.swiper-button-next-2',
        prevEl: '.swiper-button-prev-2',
    },
    breakpoints: {
        768: {
            slidesPerView: 4,
            spaceBetween: 10,
        },
    },
})

var swiper3 = new Swiper('.swiper3', {
    slidesPerView: 1.4,
    spaceBetween: 20,
    loopFillGroupWithBlank: false,
    keyboard: {
        enabled: false,
    },
    slidesOffsetAfter: 0,
    pagination: {
        el: '.swiper-pagination-3',
        clickable: true,
        renderBullet: function (index, className) {
            return `<div class="border-b-2 w-${
                index == 0 ? 12 : 12 - 2 * index
            } mr-3 ${className}"></div>`
        },
    },
    navigation: {
        nextEl: '.swiper-button-next-3',
        prevEl: '.swiper-button-prev-3',
    },
    breakpoints: {
        768: {
            slidesPerView: 4,
            spaceBetween: 10,
        },
    },
})

var swiper4 = new Swiper('.swiper4', {
    slidesPerView: 1.1,
    spaceBetween: 10,
    loopFillGroupWithBlank: false,
    keyboard: {
        enabled: false,
    },
    pagination: {
        el: '.swiper-pagination-4',
        clickable: true,
        renderBullet: function (index, className) {
            return `<div class="border-b-2 w-${
                index == 0 ? 12 : 12 - 2 * index
            } mr-3 ${className}"></div>`
        },
    },
    navigation: {
        nextEl: '.swiper-button-next-4',
        prevEl: '.swiper-button-prev-4',
    },
    breakpoints: {
        768: {
            slidesPerView: 4,
            spaceBetween: 10,
        },
    },
})

new Swiper('.swiper5', {
    slidesPerView: 1,
    spaceBetween: 10,
    slidesPerGroup: 1,
    loopFillGroupWithBlank: false,
    keyboard: {
        enabled: false,
    },
    slidesOffsetAfter: 0,
    pagination: {
        el: '.swiper-pagination-5',
        clickable: true,
        renderBullet: function (index, className) {
            return `<div class="border-b-2 w-${
                index == 0 ? 12 : 12 - 2 * index
            } mr-3 ${className}"></div>`
        },
    },
    navigation: {
        nextEl: '.swiper-button-next-5',
        prevEl: '.swiper-button-prev-5',
    },
    breakpoints: {
        768: {
            slidesPerView: 4,
            spaceBetween: 20,
            slidesPerGroup: 4,
        },
    },
})
